import React from "react";
import contact_us from "utility/contact_us";
import { NavbarComponent2 } from "components/NavBar";

export default function ContactUsPage() {
  return (
    <div className="w-[100%] h-[100vh]">
      <div className="h-[10%]">
        <NavbarComponent2 />
      </div>
      <div className="h-[90%] overflow-y-auto w-full px-7">{contact_us()}</div>
    </div>
  );
}
