import React from "react";

export default function contact_us() {
  return (
    <div className="font-sans text-base text-gray-700 space-y-4">
      <strong>Contact Us</strong>
      <p>
        Thank you for visiting Teli Samaj Parichay! We are here to assist you
        with any questions, concerns, or feedback you may have. Please feel free
        to reach out to us through the following channels:
      </p>
      <br />
      <p>
        Email Support For any queries or support, you can email us at:
        <br />
        <a href="mailto:support@telisamajparichay.in">
          support@telisamajparichay.in
        </a>
      </p>
      <br />
      We strive to respond to all email inquiries within 24-48 hours. We look
      forward to hearing from you and assisting you in your journey to finding
      the perfect match.
      <p>Thank you for choosing Teli Samaj Parichay!</p>
    </div>
  );
}
