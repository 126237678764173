import React, { useState, useEffect } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";
import PersonalDetailsForm from "helper/ProfileSetupPageHepler/PersonalDetailsForm";
import EducationalDetailsForm from "helper/ProfileSetupPageHepler/EducationalDetailsForm";
import ProfessionalDetailsForm from "helper/ProfileSetupPageHepler/ProfessionalDetailsForm";
import ParentsDetailsForm from "helper/ProfileSetupPageHepler/ParentsDetailsForm";
import YourPhotosForm from "helper/ProfileSetupPageHepler/YourPhotosForm";
import NavbarComponent from "components/NavBar";
import { fetchDropdownData } from "api/edgeFunctions";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PaymentForm from "helper/ProfileSetupPageHepler/PaymentForm";
import { deleteSelfAccount } from "api/edgeFunctions";
import { useNavigate } from "react-router-dom";
import { FaUserLock } from "react-icons/fa";
import { MdDeleteForever, MdDescription, MdEmail } from "react-icons/md";
import { RiRefund2Line } from "react-icons/ri";
import { Confirm } from "semantic-ui-react";

export default function ProfileSetupPage({ formData }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const userData = useSelector((state) => state.userData);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

  const [accordionActive, setAccordionActive] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [dropdownsData, setDropdownsData] = useState({
    highest_edu_dropdwon_list: [],
    mangal_dropdwon_list: [],
    married_dropdwon_list: [],
  });

  const formFullData = formData;

  useEffect(() => {
    fetchDropdownData()
      .then((dropdownData) => {
        setDropdownsData(dropdownData);
        setIsLoading(false);
      })
      .catch((error) => {
        alert("Error:", error);
        // setIsLoading(false);
      });
  }, [userData.data.id]);

  const accordian_list = [
    {
      id: 0,
      name: t("photos"),
      verify_bool: formFullData?.isMediaNull,
      component: <YourPhotosForm />,
    },
    {
      id: 1,
      name: t("personalDetails"),
      verify_bool: formFullData?.is_personal_details_null,
      component: (
        <PersonalDetailsForm
          dropdownsData={dropdownsData}
          setAccordionActive={setAccordionActive}
        />
      ),
    },
    {
      id: 2,
      name: t("educationalDetails"),
      verify_bool: formFullData?.is_educational_details_null,
      component: (
        <EducationalDetailsForm
          dropdownsData={dropdownsData}
          setAccordionActive={setAccordionActive}
        />
      ),
    },
    {
      id: 3,
      name: t("professionalDetails"),
      verify_bool: formFullData?.is_professional_details_null,
      component: (
        <ProfessionalDetailsForm
          dropdownsData={dropdownsData}
          setAccordionActive={setAccordionActive}
        />
      ),
    },
    {
      id: 4,
      name: t("parentsRelativesDetails"),
      verify_bool: formFullData?.is_parents_details_null,
      component: (
        <ParentsDetailsForm
          dropdownsData={dropdownsData}
          setAccordionActive={setAccordionActive}
        />
      ),
    },
    {
      id: 5,
      name: t("paymentStatus"),
      verify_bool: !formFullData?.has_paid,
      component: <PaymentForm setIsLoading={setIsLoading} />,
    },
  ];

  function handleDeleteAccount() {
    deleteSelfAccount(userData.data.id).then(async (d) => {
      navigate("/");
    });
  }

  return (
    <div className=" w-[100%] h-[100vh]">
      <div className="h-[10%]">
        <NavbarComponent
          searchBarOn={false}
          textInsteadSearchbar={t("manage_profile")}
        />
      </div>
      <div className="h-[90%] overflow-y-auto w-full px-7">
        {isLoading ? (
          <div className="h-36 items-center justify-center flex">
            <Loader />
          </div>
        ) : (
          <div className="mt-6">
            {/* <div className="text-left text-black mb-6 text-base">
              {t("profileSetup")}
            </div> */}
            {accordian_list.map((d) => (
              <div key={d.id} className="mb-3">
                <h2
                  id="accordion-open-heading-1"
                  onClick={() => {
                    setAccordionActive(accordionActive === d.id ? null : d.id);
                  }}
                >
                  <button
                    type="button"
                    className={`${
                      accordionActive === d.id ? "rounded-t-xl" : "rounded-xl"
                    } flex text-black items-center justify-between bg-[#F3F3F3] w-full p-5 font-medium     gap-3`}
                    data-accordion-target="#accordion-open-body-1"
                    aria-expanded="true"
                    aria-controls="accordion-open-body-1"
                  >
                    <span className="flex items-center">
                      <FaCircleCheck
                        size={18}
                        fill={d.verify_bool ? "#ADAFBB" : "#E94057"}
                        className="mr-4"
                      />
                      <div className="text-left text-black">{d.name}</div>
                    </span>
                    <FaAngleDown
                      fill="#BDBFB6"
                      className={`${
                        accordionActive === d.id ? "" : "-rotate-90"
                      }`}
                      size={18}
                    />
                  </button>
                </h2>

                {accordionActive === d.id ? (
                  <div
                    id="accordion-open-body-1"
                    aria-labelledby="accordion-open-heading-1"
                    className="border-2 border-[#E8E6EA] rounded-b-xl mb-3"
                  >
                    <div className="p-5  ">{d.component}</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
            {formFullData.is_valid ? (
              <div className="flex justify-center w-full items-center ">
                <div className="w-max h-max  text-primary-light font-semibold cursor-pointer relative px-4 py-1 mt-4">
                  See All Profiles
                </div>
              </div>
            ) : (
              <></>
            )}
            <hr className="my-8 h-0.5 border-t-0 bg-neutral-100" />
            <div className="mb-3">
              <button
                type="button"
                onClick={() => {
                  setSettingsOpen(!settingsOpen);
                }}
                className={` flex text-black items-center justify-between  w-full pr-5      gap-3`}
                data-accordion-target="#accordion-open-body-1"
                aria-expanded="true"
                aria-controls="accordion-open-body-1"
              >
                <div className="text-left text-black  text-base ">
                  {t("settings")}
                </div>
                <FaAngleDown
                  fill="#BDBFB6"
                  className={`${settingsOpen ? "" : "-rotate-90"}`}
                  size={18}
                />
              </button>

              {settingsOpen ? (
                <div
                  id="accordion-open-body-1"
                  aria-labelledby="accordion-open-heading-1"
                  className="mb-3"
                >
                  <div className="py-5">
                    <div
                      className="flex cursor-pointer items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-100 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80   outline-none"
                      onClick={() => {
                        navigate("/terms_and_conditions");
                      }}
                    >
                      <div className="grid place-items-center mr-4">
                        <MdDescription size={18} fill="#E94057" />
                      </div>
                      {t("termsOfUse")}
                    </div>
                    <div
                      className="flex cursor-pointer items-center mt-2 w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-100 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80   outline-none"
                      onClick={() => {
                        navigate("/privacy_policy");
                      }}
                    >
                      <div className="grid place-items-center mr-4">
                        <FaUserLock fill="#E94057" size={18} />
                      </div>
                      {t("privacyPolicy")}
                    </div>
                    <div
                      className="flex cursor-pointer items-center mt-2 w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-100 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80   outline-none"
                      onClick={() => {
                        navigate("/contact_us");
                      }}
                    >
                      <div className="grid place-items-center mr-4">
                        <MdEmail fill="#E94057" size={18} />
                      </div>
                      {t("contact_us")}
                    </div>
                    <div
                      className="flex cursor-pointer items-center mt-2 w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-100 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80   outline-none"
                      onClick={() => {
                        navigate("/refund_cancel");
                      }}
                    >
                      <div className="grid place-items-center mr-4">
                        <RiRefund2Line fill="#E94057" size={18} />
                      </div>
                      {t("refundCancelPolicy")}
                    </div>
                    <div
                      className="flex cursor-pointer items-center mt-2 w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-100 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80   outline-none"
                      onClick={() => {
                        setOpenDeleteAccount(true);
                      }}
                    >
                      <div className="grid place-items-center mr-4">
                        <MdDeleteForever fill="#E94057" size={20} />
                      </div>
                      {t("delete_account")}
                    </div>
                    {/* <div
                      className="bg-white cursor-pointer border mt-5 mb-5 mx-12 border-red-400 text-red-700 px-4 py-1 rounded flex items-center justify-center"
                      role="alert"
                    >
                      <strong
                        onClick={() => {
                          handleDeleteAccount();
                        }}
                        className="font-normal"
                      >
                        Delete Account
                      </strong>
                      <strong
                        onClick={() => {
                          // setOpenLogout(false);
                        }}
                        className="font-semibold"
                      ></strong>
                    </div> */}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      <Confirm
        open={openDeleteAccount}
        header="Are you sure you want to Delete Account?"
        content="You will be redirected to the login page."
        onCancel={() => {
          setOpenDeleteAccount(false);
        }}
        onConfirm={() => {
          handleDeleteAccount();
        }}
      />
    </div>
  );
}
