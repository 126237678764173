import React from "react";

export default function refund_cancel() {
  return (
    <div className="font-sans text-base text-gray-700 space-y-4">
      <strong>Refund and Cancellation Policy</strong>
      <p>
        Welcome to Teli Samaj Parichay. We value your trust and are committed to
        providing a transparent and user-friendly experience. Please review our
        Refund and Cancellation Policy carefully to understand the terms of use
        regarding membership fees and account management.
      </p>
      <br />
      <strong>1. No Refund Policy</strong>
      <br />
      Once a membership is purchased, the fees are non-refundable under any
      circumstances. This applies to all types of memberships and services
      offered on our platform. We recommend users carefully review the
      membership details before proceeding with the purchase.
      <br />
      <strong>2. Account Cancellation and Temporary Deactivation</strong>
      <br />
      Cancellation: Users may cancel their membership at any time. However,
      cancellation will not entitle the user to any refund or credit for the
      remaining membership period. After cancellation, the user’s profile will
      be unlisted from the platform, making it invisible to other users.
      <br />
      Temporary Deactivation:
      <br />
      Users have the option to temporarily deactivate their account during the
      membership period. Temporarily deactivated accounts can be reactivated
      within the membership duration without additional charges. Upon
      reactivation, the user’s profile will be restored and visible on the
      platform.
      <br />
      <p>
        <strong>3. Account Deletion</strong>
        <br />
        Users may choose to permanently delete their account. Once an account is
        deleted, all associated data, including the user’s profile and activity,
        will be permanently removed and cannot be recovered. If a user wishes to
        rejoin the platform after account deletion, they must register as a new
        user and purchase a membership again.
        <br />
      </p>
      <br />
      <strong>4. Contact Us</strong>
      <br />
      If you have any questions or concerns regarding our Refund and
      Cancellation Policy, please feel free to reach out to our support team at
      <br />
      <a href="mailto:support@telisamajparichay.in">
        support@telisamajparichay.in
      </a>
      <br />
      <br />
      Thank you for choosing Teli Samaj Parichay. We strive to provide the best
      experience to help you find your life partner.
    </div>
  );
}
