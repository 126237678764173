import { Routes, Route } from "react-router-dom";
import InternetConnectivityChecker from "components/InternetChecker";
import React from "react";

import LoginPage from "pages/LoginPage";
import LandingPage from "pages/LandingPage";

import { AuthProvider } from "contexts/Auth";

import "App.css";

import HomePage from "pages/HomePage";
import ProfileSetupPage from "pages/ProfileSetupPage";

import MyProfile from "pages/MyProfilePage";
import {
  AuthenticatedOnlyRoute,
  UnAuthenticatedOnlyRoute,
} from "components/Routes";

import UserProfilePage from "pages/UserProfilePage";
import ShortListedProfilesPage from "pages/ShortListedProfilesPage";
import PrivacyPolicyPage from "pages/legal/PrivacyPolicyPage";
import TermsAndConditionsPage from "pages/legal/TermsAndConditionsPage";
import ComingSoonPage from "pages/ComingSoonPage";
import ContactUsPage from "pages/legal/ContactUsPage";
import RefundCancelPage from "pages/legal/RefundCancelPage";
// There will be three types of routes

// 1. Only Authenticated users can view
// <ProfileSetupPage />

// 2. Only Unauthenticated users can view
// <LoginPage />

// 3. Authenticated & Unauthenticated can view
// <LandingPage />

// 4. Only People who have filled the form can view
// <MyProfile />

// 5. Only people who have paid can view
// <HomePage />

export function Main() {
  return (
    <AuthProvider>
      <Routes>
        {/* <Route
          path="/"
          element={
            <InternetConnectivityChecker>
              <ComingSoonPage />
            </InternetConnectivityChecker>
          }
        /> */}
        <Route
          path="/"
          element={
            <InternetConnectivityChecker>
              <LandingPage />
            </InternetConnectivityChecker>
          }
        />

        <Route
          path="/login"
          element={
            <InternetConnectivityChecker>
              <UnAuthenticatedOnlyRoute>
                <LoginPage />
              </UnAuthenticatedOnlyRoute>
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/home"
          element={
            <InternetConnectivityChecker>
              <AuthenticatedOnlyRoute
                key="/home"
                allowedIfFormNotFilled={false}
              >
                <HomePage />
              </AuthenticatedOnlyRoute>
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/profile_page_setup"
          element={
            <InternetConnectivityChecker>
              <AuthenticatedOnlyRoute allowedIfFormNotFilled={true}>
                <ProfileSetupPage />
              </AuthenticatedOnlyRoute>
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/my_profile"
          element={
            <InternetConnectivityChecker>
              <AuthenticatedOnlyRoute allowedIfFormNotFilled={false}>
                <MyProfile />
              </AuthenticatedOnlyRoute>
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/user_profile_page"
          element={
            <InternetConnectivityChecker>
              <AuthenticatedOnlyRoute allowedIfFormNotFilled={false}>
                <UserProfilePage />
              </AuthenticatedOnlyRoute>
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/sort_listed_profiles"
          element={
            <InternetConnectivityChecker>
              <AuthenticatedOnlyRoute allowedIfFormNotFilled={false}>
                <ShortListedProfilesPage />
              </AuthenticatedOnlyRoute>
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <InternetConnectivityChecker>
              <PrivacyPolicyPage />
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/terms_and_conditions"
          element={
            <InternetConnectivityChecker>
              <TermsAndConditionsPage />
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/contact_us"
          element={
            <InternetConnectivityChecker>
              <ContactUsPage />
            </InternetConnectivityChecker>
          }
        />
        <Route
          path="/refund_cancel"
          element={
            <InternetConnectivityChecker>
              <RefundCancelPage />
            </InternetConnectivityChecker>
          }
        />
      </Routes>
    </AuthProvider>
  );
}
