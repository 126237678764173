import React, { useState, useEffect } from "react";
import { loginWithEmailOTP, loginWithEmailPassword } from "api/auth";
import { supabase } from "supabase/supabaseClient";
import { useForm } from "react-hook-form";
import InputComponent from "components/InputComponent";
import { sendLogToServer } from "api/logging";
import ButtonComponent from "components/ButtonComponent";
import { MoveLeft } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function LoginPage() {
  const { t } = useTranslation();

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  const [otpScreen, setOtpScreen] = useState(false);

  const [timer, setTimer] = useState(120);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const signIn = async (authData) => {
    setEmail(authData.email);
    setOtpScreen(true);
    loginWithEmailOTP(authData.email);
  };

  const verifyOTP = async () => {
    sendLogToServer("verifyOTP Started");

    try {
      const {
        data: { session },
        error,
      } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: "email",
      });

      if (session === null) {
        alert("Otp Invalid");
      }
    } catch (error) {
      sendLogToServer(`Error fetching session:${error}`);
      return error;
    }
  };

  useEffect(() => {
    let interval;
    if (otpScreen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [otpScreen, timer]);

  return (
    <div className=" w-[100%] h-[100vh] flex justify-start items-center flex-col px-14 ">
      {otpScreen === false ? (
        <>
          <div className="flex flex-col  justify-center  space-y-2 text-left mt-32">
            <p className="font-light text-3xl ">{t("myEmail")}</p>
            <p className="flex flex-row text-sm font-medium text-gray-400">
              {t("enterValidEmailPrompt")}
            </p>
          </div>

          <form
            onSubmit={handleSubmit(signIn)}
            className="mt-12 flex justify-center flex-col w-full "
          >
            <InputComponent
              id="email"
              label={null}
              placeholder={t("enterEmailID")}
              type="email"
              step=""
              register={{
                ...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }),
              }}
            />
            <div className="mt-12">
              <ButtonComponent
                onClick={() => {}}
                name={t("loginWithEmail")}
                type="submit"
              />
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="h-full  w-full pt-10">
            <div className="w-full">
              <button
                onClick={() => {
                  setOtpScreen(false);
                  setTimer(120);
                }}
                type="button"
                className="flex mb-14 flex-shrink-0 justify-center items-center gap-2 h-[2.875rem] w-[2.875rem] text-sm font-semibold rounded-xl border border-gray-200 bg-white text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none "
              >
                <MoveLeft size={18} className=" text-primary-light" />
              </button>
              <div className="w-full items-center flex flex-col ">
                <p className="font-semibold text-3xl">{timer}</p>
                <p className="flex w-60 mt-5 text-center flex-row text-base font-medium text-gray-400">
                  {t("typeVerificationCode")} - {email}
                </p>
                <div className="mt-5 w-full">
                  <InputComponent
                    id="otp"
                    label={null}
                    placeholder={t("enterOTP")}
                    type="number"
                    step=""
                    setInputValue={setOtp}
                  />
                </div>
                <button
                  onClick={() => {
                    setOtpScreen(false);
                    setTimer(120);
                  }}
                  className="py-3 mt-6 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-white text-primary-light"
                >
                  {t("sendAgain")}
                </button>
                <button
                  onClick={() => {
                    verifyOTP();
                  }}
                  className="py-3 w-full flex items-center justify-center mt-8 px-4  gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-light text-white"
                >
                  {t("continue")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
